const ACTION_GETTING = 'GEOLOCATION/GETTING'
const ACTION_ENABLED = 'GEOLOCATION/ENBLED'
const ACTION_ERROR = 'GEOLOCATION/ERROR'

const initState = {
    enabled: document.cookie.match('sharedLocation'),
    getting: false,
    coords: null,
    error: null
}

export default function (state = initState, { type, payload }) {
    switch(type) {
        case ACTION_GETTING:
            return {
                ...state,
                getting: true
            }
        case ACTION_ENABLED:
            document.cookie = "sharedLocation=1; expires=" + new Date(new Date().getTime() + ((3600 * 24 * 30) * 1000)) + "";
            return {
                ...state,
                getting: false,
                enabled: true,
                coords: payload
            }
        case ACTION_ERROR:
            return {
                ...state,
                getting: false,
                enabled: false,
                coords: null,
                error: payload
            }
        default:
            return state;
    }
}

export function enableLocation() {
    return dispatch => {
        dispatch({
            type: ACTION_GETTING,
            payload: true
        });
        navigator.geolocation.getCurrentPosition(position => {
            dispatch({
                type: ACTION_ENABLED,
                payload: position.coords
            })
        }, err => {
            dispatch({
                type: ACTION_ERROR,
                payload: err
            })
        })
    }
}